import React from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';

import WebChat from './WebChat';
import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleFetchToken = this.handleFetchToken.bind(this);
    this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
    this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);

    const store = createStore({}, ({ dispatch }) => next => action => {
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        setTimeout(() => {
          dispatch({
            type: 'DIRECT_LINE/POST_ACTIVITY',
            payload: {
              activity: {
                name: 'webchat/join',
                type: 'event',
                value: {
                  language: window.navigator.language
                }
              }
            }
          });
        }, 1000);
      } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        if (action.payload.activity.from.role === 'bot') {
          this.setState(() => ({
            newMessage: true
          }));
        }
      }
      return next(action);
    });

    this.state = {
      minimized: true,
      newMessage: false,
      store,
      styleSet: createStyleSet({
        backgroundColor: 'Transparent'
      }),
      token: null
    };
  }

  async handleFetchToken() {
    if (!this.state.token) {
      this.setState(() => ({
        token: process.env.REACT_APP_TOKEN
      }));
    }
  }

  handleMaximizeButtonClick() {
    this.setState(() => ({
      minimized: false,
      newMessage: false
    }));
  }

  handleMinimizeButtonClick() {
    this.setState(() => ({
      minimized: true,
      newMessage: false
    }));
  }

  render() {
    const { state: { minimized, store, styleSet, token } } = this;
    return (
      <div className="minimizable-web-chat disable-selection">
        {
          minimized ?
            <div className="maximize">
              <object type="image/svg+xml" data="/img/dialogo.svg" className="img-responsive" >
                Coboty
              </object>
              <div className="overclick" onClick={this.handleMaximizeButtonClick}></div>
            </div>
            :
            <div className="chat-box right">
              <header>
                <img className="img-chat" src="/img/avatar.png" alt="avatar coboty" onClick={this.handleMaximizeButtonClick} />
                <div className="filler">
                  <p>¡Preguntale a Coboty!</p>
                </div>
                <div className="minimize-container" onClick={this.handleMinimizeButtonClick}>
                  <img src="/img/minimize.png" alt="minimize" className="minimize" />
                </div>
              </header>
              <WebChat
                className="react-web-chat"
                onFetchToken={this.handleFetchToken}
                store={store}
                styleSet={styleSet}
                token={token}
              />
            </div>
        }
      </div>
    );
  }
}
