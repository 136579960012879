import memoize from 'memoize-one';
import React from 'react';
import ReactWebChat, { createDirectLine, createStyleSet, connectToWebChat } from 'botframework-webchat';
import ActivityWithFeedback from './ActivityWithFeedback';
import './WebChat.css';

let message, locale;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.createDirectLine = memoize(token => createDirectLine({ token }));

    this.state = {
      styleSet: createStyleSet({
        backgroundColor: 'Transparent',
        hideUploadButton: true,
        fontSizeSmall: '100%',
        bubbleBackground: 'white',
        bubbleFromUserBackground: '#dcf8c6',
        bubbleBorderRadius: 15,
        bubbleFromUserBorderRadius: 15,
        bubbleTextColor: '#000',
        bubbleFromUserTextColor: '#000',
        sendBoxButtonColor: '#46B194',
        sendBoxHeight: 50,
      })
    };

    this.ConnectedActivityWithFeedback = connectToWebChat(
      ({ postActivity }) => ({ postActivity })
    )(props => <ActivityWithFeedback {...props} />)

    var lastQuestion = null;
    this.activityMiddleware = () => next => card => {

      if (card.activity.from.role !== 'bot') {
        lastQuestion = card.activity.text;
      }

      if (card.activity.from.role === 'bot') {
        return (
          children =>
            <this.ConnectedActivityWithFeedback activityID={card.activity} question={lastQuestion} used={false}>
              {next(card)(children)}
            </this.ConnectedActivityWithFeedback>
        );
      } else {
        return next(card);
      }
    };
  }

  componentDidMount() {
    !this.props.token && this.props.onFetchToken();
  }

  render() {
    const {
      props: { className, store, token },
      state: { styleSet }
    } = this;

    // Hide upload button
    styleSet.uploadButton = { ...styleSet.uploadButton,
      display: 'none'
    };
    
    // Font bubbles
    styleSet.textContent = {
      ...styleSet.textContent,
      fontFamily: "'Roboto', sans-serif"
    };

    // Avatars
    styleSet.avatar = {
      ...styleSet.avatar,
      '&:not(.from-user)': {
        backgroundImage: "url(/img/avatar.png)",
        backgroundSize: "cover",
        backgroundColor: 'transparent'
      }
    };

    console.log(styleSet);

    message = "Espere mientras lo conectamos...";
    locale = "es-ES";

    return (
      token ?
        <ReactWebChat
          activityMiddleware={this.activityMiddleware}
          className={`${className || ''} web-chat`}
          directLine={this.createDirectLine(token)}
          store={store}
          styleSet={styleSet}
          locale={locale}
          botAvatarInitials=' '
        />
        :
        <div className={`${className || ''} connect-spinner`}>
          <div className="content">
            <div className="icon">
              <span className="ms-Icon ms-Icon--Robot" />
            </div>
            <p>{message}</p>
          </div>
        </div>
    );
  }
}
