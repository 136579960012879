import React from 'react';
const { css } = window.Glamor;

const ACTIVITY_WITH_FEEDBACK_CSS = css({
    minHeight: 60,
    position: 'relative',
    '& > .activity': {
        padding: '0 40px 0 20px'
    },
    '& > .button-bar': {
        listStyleType: 'none',
        position: 'absolute',
        right: '10px',
        top: '0',
        '& > li > img': {
            cursor: 'pointer'
        }
    }
});

class ActivityWithFeedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = { used: '' };
    }

    handleDownvoteButton = () => {
        this.setState({ used: true });
        this.props.postActivity({
            type: 'message',
            name: 'evaluate-activity',
            value: {
                id: this.props.activityID.id,
                question: this.props.question,
                vote: 'DOWN'
            }
        });
    }
    handleUpvoteButton = () => {
        this.setState({ used: true });
        this.props.postActivity({
            type: 'message',
            name: 'evaluate-activity',
            value: {
                id: this.props.activityID.id,
                question: this.props.question,
                vote: "UP"
            }
        })
    }
    render() {
        const { props } = this;

        return (
            <div id='feedback' className={ACTIVITY_WITH_FEEDBACK_CSS}>
                { !this.state.used ?
                    <ul className="button-bar">
                        <li>
                            <img src="/img/like.png" alt="" onClick={this.handleUpvoteButton} />
                        </li>
                        <li>
                            <img src="/img/dislike.png" alt="" onClick={this.handleDownvoteButton} />
                        </li>
                    </ul>
                    :
                    <ul></ul>
                }
                <div className="activity">
                    {props.children}
                </div>
            </div>
        );
    }
}

export default ActivityWithFeedback;